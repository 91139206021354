import React from 'react'
import Tribe from './Tribe'

import { graphql, useStaticQuery } from "gatsby";

const TribeListing = ({ path }) => {

    const data = useStaticQuery(graphql`
        query ourTribes {
            ourTribes: allContentfulTribes(
                filter: { path: { eq: "/ourstory" }}
                sort: { fields: orderId }
            ) 
            {
            edges {
                node {
                title
                orderId
                name
                image {
                    fluid {
                    src
                    }
                }
                modal {
                    childContentfulRichText {
                    html
                    }
                }
                }
            }
            }
        } 
    `)

    return (
        <div>
            <div className="container">
                <div className="row">
                    {
                            data.ourTribes.edges.map((edge) => {
                                return (
                                    <Tribe
                                    key={edge.node.orderId}
                                    id={edge.node.orderId}
                                     title={edge.node.title}
                                     name={edge.node.name} 
                                     modal={edge.node.modal.childContentfulRichText.html}
                                     image={edge.node.image.fluid.src}
                                     />
                                )
                            })

                    }
                </div>
            </div>
        </div>
    )
}

export default TribeListing


