import React, { Component } from "react"
import t1 from "../../assets/ourstory/t1.png"
import { Modal, Button } from "react-bootstrap"
import axios from "axios"
import VHCenter from "../elements/VHCenter"
import styled from "styled-components"
import "./tribe.css"
import './modal.css'
const Img = styled.img`
  width: 215px;
  height: 215px;
  color: rgb(230, 157, 105);
`

export default class ModalforImage extends React.Component {


  state = {
    data: null,
  }

  handleShow = () => {
    this.setState({
      show: true,
    })
  }

  handleClose = () => {
    this.setState({
      show: false,
    })
  }

  render() {
    const { data } = this.state

    return (
      <React.Fragment>
        <Img
          src={this.props.src}
          alt="Our tribe member"
          className=""
        />
        <div
          className="overlay"
          onClick={() => {
            this.handleShow();
            // this.getData(this.props.id);
          }}

        >



          <div className="text">
            <h3>{this.props.name}</h3>
            <h6 style={{ fontSize: "14px" }}>{this.props.title}</h6>
            {/* <p> {image.age}</p> */}
          </div>
        </div>




        {
          <Modal
            // {...this.props}
            show={this.state.show}
            onHide={this.handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton style={{ backgroundColor: "#ed6c2b" }}>
              <div className="center w-100">
                <div>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{
                      backgroundColor: "#ed6c2b",
                      color: "white",
                      align: "center",
                    }}
                  >
                    {/* {this.props.id} */}
                    {this.props.name}
                  </Modal.Title>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body className="text-justify" style={{ backgroundColor: "#ed6c2b", color: "white" }}>
              <div dangerouslySetInnerHTML={{ __html: this.props.modal }}></div>
            </Modal.Body>
          </Modal>
        }
      </React.Fragment>
    )
  }
}
