import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ValueListing from '../components/ListingComponent'
import TribeListing from "../components/ourstory/TribeListing"
import ImgHeader from "../components/ImgHeader"
import CenterDiv from "../components/elements/CenterDiv"
import TitleDiv from "../components/elements/TitleDiv";
import VHCenter from "../components/elements/VHCenter";
import Title from "../components/elements/Title"
import image from "../assets/ourstory/Our-Story.jpg"

const Div = styled.div`
  background-image: url(${(props) => props.image});
  height: 55vh;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;

`

const OurStory = ({ location, data }) => {
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src
  const { title } = data.ImgHeaderQuery
  const html = data.ContentBlockQuery.body.childContentfulRichText.html
  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header
  const thirdTitle = data.TitleDivQuery.edges[2].node.header
  return (
    <Layout>
      <SEO title="Our Story" />
      <ImgHeader image={background} />
      <TitleDiv color="#feb600" style={{ marginTop: "0" }}>
        <VHCenter><div className="text-uppercase"><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div>
        <CenterDiv className="container">
          <div className="avenir-roman font-size" dangerouslySetInnerHTML={{ __html: html }}></div>
        </CenterDiv>
      </div>
      <TitleDiv color="#febf20">
        <VHCenter><div class="text-uppercase"><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <ValueListing path="/ourstory" />
      {/* <Values /> */}
      <TitleDiv color="#ed6c2b">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: thirdTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <TribeListing />
    </Layout >
  )
}

export default OurStory

export const query = graphql`
  query OurStoryQuery {
    ImgHeaderQuery: contentfulImgHeader(path: { eq: "/ourstory" }) {
      title
      path
      backgroundImg {
        fluid(quality: 100) {
          src
        }
      }
    }
    ContentBlockQuery: contentfulBlocks(path: { eq: "/ourstory" }) {
      body {
        childContentfulRichText {
          html
        }
      }
    }
    
    TitleDivQuery: allContentfulTitleDiv(filter: { path: { eq: "/ourstory" }}, sort: { fields: orderId })
    {
      edges {
        node {
          orderId
          header 
        }
      }
    }
    
  }
`


