import React, { useState } from "react"
import t1 from "../../assets/ourstory/rabi.png"
import t2 from "../../assets/ourstory/aisha.png"
import t3 from "../../assets/ourstory/rooh.png"
import t4 from "../../assets/ourstory/t4.png"
import t5 from "../../assets/ourstory/latifa.png"
import t6 from "../../assets/ourstory/t6.png"
import overlay from "./images/layer-2-copy-6.png"
import ModalforImage from "./modal"

// import styled from "styled-components"
import VHCenter from "../elements/VHCenter"
import CenterDiv from "../elements/CenterDiv"

const Tribe = ({ name, title, id, modal, image }) => {
  return (
    <div className="col-sm-12 col-md-4 text-center hover-image" style={{ marginTop: "2%", marginBottom: "2%" }}>
      <ModalforImage
        id={id}
        title={title}
        name={name}
        //   email="Contact Rabea: Rabea@thehappinessfitt.com"
        //   phone="Contact Number: +971-55-459-8660"
        src={image}
        modal={modal}
      />
    </div>
  )

}
export default Tribe
